/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {NgClass} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, input, model} from '@angular/core';
import {IconComponent} from '@dv/shared/angular';
import {TranslocoModule} from '@jsverse/transloco';

@Component({
    selector: 'dv-collapsible-panel',
    imports: [IconComponent, TranslocoModule, NgClass],
    templateUrl: './collapsible-panel.component.html',
    styleUrl: './collapsible-panel.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollapsiblePanelComponent {
    public isFirst = input<boolean>(false);
    public isLast = input<boolean>(false);
    public isCollapsed = model<boolean>(true);
    public compact = input<boolean>(false);
    public isDisabled = input<boolean>(false);

    public showHeader = computed(() => this.isCollapsed() || this.isDisabled());
    public showContent = computed(() => !this.isCollapsed() && !this.isDisabled());

    public toggle(): void {
        this.isCollapsed.update(value => !value);
    }
}
