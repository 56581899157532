/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {DvLocalDateFormatPipe, LetDirective, LoadingButtonComponent} from '@dv/shared/angular';
import {TerminationMode} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import moment from 'moment';
import type {TerminateDialogModel} from '../../models/TerminateDialogModel';
import {CompletableDialogBase} from '../completable-dialog.base';

@Component({
    selector: 'dv-terminate-dialog',
    templateUrl: './terminate-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        TranslocoModule,
        FormsModule,
        DvLocalDateFormatPipe,
        LetDirective,
        LoadingButtonComponent,
    ],
})
export class TerminateDialogComponent extends CompletableDialogBase<{ date: moment.Moment; mode: TerminationMode }>
    implements TerminateDialogModel {

    @Input() public translationRoot!: string;
    @Input() public endDate!: moment.Moment;

    public terminationModes: TerminationMode[] = Object.values(TerminationMode);
    public terminationMode: TerminationMode = TerminationMode.TERMINATE;

    public override submit(): void {
        super.submit({date: this.endDate, mode: this.terminationMode});
    }
}
