/*
 * Copyright © 2024 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {Directive, input} from '@angular/core';
import type {CrudAccordionTemplateContext} from './crud-accordion-template-context';

@Directive({
    selector: 'ng-template[dvCrudAccordionTemplate]',
})
export class CrudAccordionTemplateDirective<T> {
    public items = input.required<T[]>({alias: 'dvCrudAccordionTemplate'});

    public static ngTemplateContextGuard<TContext>(
        _: CrudAccordionTemplateDirective<TContext>,
        ctx: unknown,
    ): ctx is CrudAccordionTemplateContext<TContext> {
        return true;
    }
}
