import {NgTemplateOutlet} from '@angular/common';
import {ChangeDetectionStrategy, Component, input, output, TemplateRef} from '@angular/core';
import {
    ButtonListComponent,
    ConfirmDialogComponent,
    DvLocalDateFormatPipe,
    SpinnerComponent,
} from '@dv/shared/angular';
import {EntityId} from '@dv/shared/backend/model/entity-id';
import {TranslocoModule} from '@jsverse/transloco';
import moment from 'moment/moment';
import {CollapsiblePanelComponent} from '../../collapsible-panel/collapsible-panel.component';
import {GueltigkeitComponent} from '../gueltigkeit/gueltigkeit.component';
import {GueltigkeitTemplateContext} from './gueltigkeit-template-context';
import {GueltigkeitsVerlaufEntity, GueltigkeitVerlaufItemWithEntity} from './gueltigkeit-verlauf-store';
import {GueltigkeitVerlaufTemplateDirective} from './gueltigkeit-verlauf-template.directive';

@Component({
    selector: 'dv-gueltigkeit-verlauf',
    imports: [
        NgTemplateOutlet,
        SpinnerComponent,
        ButtonListComponent,
        CollapsiblePanelComponent,
        DvLocalDateFormatPipe,
        TranslocoModule,
        ConfirmDialogComponent,
        GueltigkeitVerlaufTemplateDirective,
        GueltigkeitComponent,
    ],
    templateUrl: './gueltigkeit-verlauf.component.html',
    styles: `
        :host {
            display: block;
        }
    `,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GueltigkeitVerlaufComponent<Entity extends GueltigkeitsVerlaufEntity,
    T extends GueltigkeitVerlaufItemWithEntity<Entity> = GueltigkeitVerlaufItemWithEntity<Entity>> {

    public title = input<string>();
    public isLoading = input(false);
    public isEmpty = input.required<boolean>();
    public emptyText = input<string>();
    public items = input.required<T[]>();
    public entityName = input.required<string>();
    public readonly = input(false);

    public readonly terminate = output<moment.Moment>();
    public readonly extend = output<EntityId>();
    public readonly toggleExpanded = output<EntityId>();

    public readonly editEntity = output<T['entity']>();
    public readonly deleteEntity = output<T['entity']>();

    public editModeTemplate = input<TemplateRef<GueltigkeitTemplateContext<T>>>();
    public createModeTemplate = input<TemplateRef<GueltigkeitTemplateContext<T>>>();
    public readonlyModeTemplate = input<TemplateRef<GueltigkeitTemplateContext<T>>>();
    public readonlyModeActionsTemplate = input<TemplateRef<GueltigkeitTemplateContext<T>>>();
    public showDeleteDialog = input(false);
    public readonly confirmDelete = output();
    public readonly hideDeleteDialog = output();
}
