import {NgClass} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, input, output} from '@angular/core';

@Component({
    selector: 'dv-accordion',
    imports: [NgClass],
    templateUrl: './accordion.component.html',
    styleUrl: './accordion.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionComponent {
    public expanded = input.required<boolean>();
    public index = input.required<number>();

    public isFirst = computed(() => this.index() === 0);
    private detailsState = computed(() => this.expanded() ? 'open' : 'closed');

    public readonly toggleEvent = output<void>();

    public detailsToggle($event: Event): void {
        // changing expanded from the outside triggers ToggleEvent. This avoids an endless loop.
        // use a type cast instead of instance of because ToggleEvent is not implemented in jsdom
        if (($event as any).newState !== this.detailsState()) {
            this.toggleEvent.emit();
        }
    }
}
