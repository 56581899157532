/*
 * Copyright © 2021 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {ChangeDetectionStrategy, Component, computed, inject, input} from '@angular/core';
import {checkPresent, DvbDateUtil, DvbUtil, SupportedDateTypes} from '@dv/shared/code';
import {Translator} from '@dv/shared/translator';
import {TranslocoModule} from '@jsverse/transloco';

@Component({
    selector: 'dv-gueltigkeit',
    imports: [
        TranslocoModule,
    ],
    templateUrl: './gueltigkeit.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GueltigkeitComponent {

    private readonly translator = inject(Translator);

    public entity = input.required<{
        gueltigAb?: SupportedDateTypes | null;
        gueltigBis?: SupportedDateTypes | null;
    }>();

    public translationParams = computed(() => {
        const entity = this.entity();

        const limited = {
            gueltigAb: DvbDateUtil.toMoment(entity.gueltigAb),
            gueltigBis: DvbDateUtil.toMoment(entity.gueltigBis),
        };

        const today = DvbDateUtil.today();

        const isActive = DvbDateUtil.isGueltigOn(limited, today);
        const isBeginOfTime = DvbDateUtil.isBeginOfTime(limited.gueltigAb);

        const prepositionKey = DvbDateUtil.getTemporalPrepositionKey(limited, today);
        let preposition = this.translator.instant(checkPresent(prepositionKey));

        if (!isActive) {
            preposition = DvbUtil.capitalize(preposition);
        }

        return {
            preposition,
            isActive,
            isBeginOfTime,
            date: limited.gueltigAb?.format('D.M.YYYY'),
        };
    });
}
