import {FocusTrap, FocusTrapFactory} from '@angular/cdk/a11y';
import {ConnectedPosition, Overlay, OverlayConfig, OverlayRef} from '@angular/cdk/overlay';
import {TemplatePortal} from '@angular/cdk/portal';
import {
    Directive,
    effect,
    ElementRef,
    inject,
    input,
    InputSignal,
    OnDestroy,
    output,
    TemplateRef,
    untracked,
    ViewContainerRef,
} from '@angular/core';
import {checkPresent, isPresent} from '@dv/shared/code';
import {merge, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';

const POSITIONS: ConnectedPosition[] = [
    // position below element
    {
        originX: 'center',
        originY: 'bottom',
        overlayX: 'center',
        overlayY: 'top',
        offsetY: 15,
        panelClass: 'below',
    },
    // position above element
    {
        originX: 'center',
        originY: 'top',
        overlayX: 'center',
        overlayY: 'bottom',
        offsetY: -15,
        panelClass: 'above',
    },
];

@Directive({
    selector: '[dvOverlay]',
})
export class OverlayDirective<T = unknown> implements OnDestroy {
    public overlayTemplate = input<TemplateRef<T>>();
    public overlayOpen: InputSignal<boolean> = input.required<boolean>();
    public overlayContext = input<T>();

    public readonly closeOverlay = output<void>();

    private overlayRef?: OverlayRef;
    private focusTrap?: FocusTrap;
    private subscription?: Subscription;

    // noinspection JSUnusedLocalSymbols
    private toggleOverlay = effect(() => {
        if (this.overlayOpen()) {
            untracked(() => this.openOverlay());
        } else {
            this.overlayRef?.detach();
        }
    });

    private element = inject(ElementRef);
    private viewContainer = inject(ViewContainerRef);
    private readonly overlay = inject(Overlay);
    private readonly focusTrapFactory = inject(FocusTrapFactory);

    private openOverlay(): void {
        const overlayTemplate = this.overlayTemplate();

        if (!isPresent(overlayTemplate)) {
            return;
        }

        const config = new OverlayConfig({
            hasBackdrop: true,
            backdropClass: 'cdk-overlay-transparent-backdrop',
            scrollStrategy: this.overlay.scrollStrategies.reposition(),
            positionStrategy: this.overlay
                .position()
                .flexibleConnectedTo(checkPresent(this.element))
                .withGrowAfterOpen(true)
                .withPositions(POSITIONS),
        });

        this.overlayRef?.detach();
        this.overlayRef = this.overlay.create(config);
        this.subscription = merge(
            this.overlayRef.backdropClick(),
            this.overlayRef.keydownEvents()
                .pipe(filter(event => event.key === 'Escape')),
        )
            .subscribe(() => {
                // reset focus to the element that triggered the overlay to be opened
                this.element.nativeElement.focus();
                this.closeOverlay.emit();
            });

        const portal = new TemplatePortal(overlayTemplate, this.viewContainer, this.overlayContext());

        this.overlayRef.attach(portal);
        this.applyFocusTrap();
    }

    private applyFocusTrap(): void {
        if (!this.overlayRef) {
            return;
        }

        const overlayElement = this.overlayRef.overlayElement;
        this.focusTrap = this.focusTrapFactory.create(overlayElement);

        this.focusTrap.focusInitialElementWhenReady();
    }

    public ngOnDestroy(): void {
        this.subscription?.unsubscribe();
        this.focusTrap?.destroy();
    }
}
