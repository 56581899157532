/*
 * Copyright © 2023 DV Bern AG, Switzerland
 *
 * Das vorliegende Dokument, einschliesslich aller seiner Teile, ist urheberrechtlich
 * geschützt. Jede Verwertung ist ohne Zustimmung der DV Bern AG unzulässig. Dies gilt
 * insbesondere für Vervielfältigungen, die Einspeicherung und Verarbeitung in
 * elektronischer Form. Wird das Dokument einem Kunden im Rahmen der Projektarbeit zur
 * Ansicht übergeben, ist jede weitere Verteilung durch den Kunden an Dritte untersagt.
 */

import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, inject, Input} from '@angular/core';
import type {NgForm} from '@angular/forms';
import {FormsModule} from '@angular/forms';
import {ErrorService} from '@dv/kitadmin/core/errors';
import {ButtonListComponent, DatepickerTextfieldComponent, LoadingButtonComponent} from '@dv/shared/angular';
import {DateLimitation, DvbDateUtil, isNullish} from '@dv/shared/code';
import {TranslocoModule} from '@jsverse/transloco';
import moment from 'moment';
import type {DateDialogModel} from '../../models/DateDialogModel';
import {CompletableDialogBase} from '../completable-dialog.base';

@Component({
    selector: 'dv-date-dialog',
    templateUrl: './date-dialog.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        FormsModule,
        TranslocoModule,
        LoadingButtonComponent,
        DatepickerTextfieldComponent,
        ButtonListComponent,
    ],
})
export class DateDialogComponent extends CompletableDialogBase<moment.Moment> implements DateDialogModel {
    private errorService = inject(ErrorService);

    @Input() public title!: string;
    @Input() public subtitle?: string;
    @Input() public placeholder?: string;
    @Input() public confirmActionText?: string;
    @Input() public cancelActionText?: string;
    @Input() public inverseButtons: boolean = false;
    @Input() public dateLimitation?: DateLimitation;

    public endDate: moment.Moment | null = null;

    public submitForm(form: NgForm): void {

        this.errorService.clearAll();

        const formValid = !form.invalid;
        const validMoment = DvbDateUtil.isValidMoment(this.endDate);

        this.errorService.handleValidationError(formValid, 'ERRORS.ERR_INCOMPLETE_FORM');
        this.errorService.handleValidationError(validMoment, 'ERRORS.ERR_INVALID_DATE');

        if (validMoment && formValid) {
            super.submit(this.endDate!);
        }
    }

    public applyDateLimitation(): void {
        if (isNullish(this.endDate)) {
            this.endDate = null;

            return;
        }

        if (this.dateLimitation === 'endOfMonth') {
            this.endDate = moment(DvbDateUtil.adjustToEndOfMonth(this.endDate));
        }
        if (this.dateLimitation === 'endOfWeek') {
            this.endDate = moment(DvbDateUtil.adjustToEndOfWeek(this.endDate));
        }
    }
}
