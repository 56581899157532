<dvlib-button-list class="normal-abstand-top" *transloco="let t">
    <dvlib-loading-button type="submit" [isLoading]="isLoading()">
        <span>{{ t(submitKey()) }}</span>
    </dvlib-loading-button>
    <button type="button"
            class="btn btn-link"
            (click)="cancelEvent.emit()"
            [disabled]="isLoading()">{{ t(cancelKey()) }}
    </button>
</dvlib-button-list>
