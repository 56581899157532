import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
    selector: 'dv-entity-list-item',
    templateUrl: './entity-list-item.component.html',
    styleUrl: './entity-list-item.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EntityListItemComponent {
}
